import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import './Navbar.css';
import { IconContext } from 'react-icons'
//import logo from '../pixel_assets/pixel_inspector.png';
import logo from '../pixel_assets/examiner.png';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  return (
    <>
    <IconContext.Provider value={{color: '#fff'}}>
      <div className="navbar">
        
        <Link to="#" className="menu-bars">
          <FaIcons.FaBars onClick={showSidebar} />
        </Link>
        {/*<img src={logo} style={{marginTop: 1 + 'em'}} alt="logo" />*/}
        <p className="snes_font center logo_text_pad">Pixel Examiner <img src={logo} className="logo_pad" alt="logo" /></p>
        

      </div>
      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items" onClick={showSidebar}>
          <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
              <AiIcons.AiOutlineClose />
            </Link>
          </li>
          {SidebarData.map((item, index) => {
            return <li key={index} className={item.cName}>
              <Link to ={item.path}>
                {item.icon}
                <span className="goblin_font_sidebar">{item.title}</span>
              </Link>
            </li>;
          })}
        </ul>
      </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
